import * as actionTypes from "src/actions";
import jwt_decode from "jwt-decode";

const initialState = {
  loading: false,
  loggedIn: false,
  user: {
    role: "", // ['sugarAdmin', 'superAdmin', 'storeAdmin', 'serviceAdmin']
  },
  userDetails: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      console.log(actionTypes);
      localStorage.setItem("token", action.payload);

      const token = jwt_decode(action.payload);

      return {
        ...initialState,
        user: {
          role: token.userType[0], // ROLE/USERTYPE - CHANGE HERE ACC TO DATA RECEIVED
        },
        loggedIn: true,
      };
      break;
    }

    case actionTypes.LOADING: {
      return {
        ...initialState,
        loading: action.payload,
      };
      break;
    }

    case actionTypes.LOAD_USER: {
      console.log(action.type);
      return {
        ...initialState,
        loggedIn: true,
        user: {
          role: action.payload.userType[0], // ROLE/USERTYPE - CHANGE HERE ACC TO DATA RECEIVED
        },
      };
    }
    case actionTypes.SESSION_LOGOUT: {
      console.log(action.type);
      localStorage.removeItem("token");
      localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        user: {
          role: "",
        },
      };
    }

    case actionTypes.COMPLETE_USER: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
