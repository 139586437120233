import uuid from "uuid/v1";
import moment from "moment";
import mock from "src/utils/mock";

mock.onGet("/api/coupon").reply(200, {
  "totalCount": 0,
  "pageNumber": 0,
  "pageItemsCount": 0,
  "nextPage": 0,
  "previousPage": 0,
  "data": [
    {
      "id": "22229dd2-1c60-11eb-adc1-0242ac120002",
      "status": "active",
      "couponCode": "PUNE50",
      "couponDescription": "First 50 users to get this token",
      "minAmount": 50,
      "maxAmount": 100,
      "discountType": "flat",
      "discountValue": 10,
      "couponOn": [
        {
          "rule": "multiplier",
          "ruleDescription": "Buy one get one free",
          "ruleValue": "1"
        },
        {
          "rule": "category",
          "ruleDescription": "fertilizer",
          "ruleValue": "fertilizer"
        }
      ],
      "redeemedCount": 2,
      "redeemedBy": [
        "10936b32-1c60-11eb-adc1-0242ac120002",
        "22229dd2-1c60-11eb-adc1-0242ac120002"
      ],
      "couponExpiry": "2017-07-21T17:32:28Z",
      "createdOn": "2017-07-21T17:32:28Z",
      "updatedOn": "2020-07-21T17:32:28Z"
    },
    {
      "id": "22229dd2-1c60-11eb-adc1-0242ac120002",
      "status": "active",
      "couponCode": "FIRST",
      "couponDescription": "For New User",
      "minAmount": 99,
      "maxAmount": 199,
      "discountType": "flat",
      "discountValue": 15,
      "couponOn": [
        {
          "rule": "multiplier",
          "ruleDescription": "Buy one get two free",
          "ruleValue": "1"
        },
        {
          "rule": "category",
          "ruleDescription": "fertilizer",
          "ruleValue": "fertilizer"
        }
      ],
      "redeemedCount": 9,
      "redeemedBy": [
        "10936b32-1c60-11eb-adc1-0242ac120002",
        "22229dd2-1c60-11eb-adc1-0242ac120002"
      ],
      "couponExpiry": "2017-07-21T17:32:28Z",
      "createdOn": "2017-07-21T17:32:28Z",
      "updatedOn": "2020-07-21T17:32:28Z"
    },
    {
      "id": "22229dd2-1c60-11eb-adc1-0242ac120002",
      "status": "active",
      "couponCode": "NEWYEAR",
      "couponDescription": "Offer applicable till 31st Ded",
      "minAmount": 20,
      "maxAmount": 90,
      "discountType": "flat",
      "discountValue": 80,
      "couponOn": [
        {
          "rule": "multiplier",
          "ruleDescription": "Buy one get one free",
          "ruleValue": "1"
        },
        {
          "rule": "category",
          "ruleDescription": "fertilizer",
          "ruleValue": "fertilizer"
        }
      ],
      "redeemedCount": 92,
      "redeemedBy": [
        "10936b32-1c60-11eb-adc1-0242ac120002",
        "22229dd2-1c60-11eb-adc1-0242ac120002"
      ],
      "couponExpiry": "2017-07-21T17:32:28Z",
      "createdOn": "2017-07-21T17:32:28Z",
      "updatedOn": "2020-07-21T17:32:28Z"
    },
    {
      "id": "22229dd2-1c60-11eb-adc1-0242ac120002",
      "status": "active",
      "couponCode": "FLAT100",
      "couponDescription": "Flat 100 rs discount",
      "minAmount": 50,
      "maxAmount": 100,
      "discountType": "flat",
      "discountValue": 10,
      "couponOn": [
        {
          "rule": "multiplier",
          "ruleDescription": "Buy one get three free",
          "ruleValue": "1"
        },
        {
          "rule": "category",
          "ruleDescription": "fertilizer",
          "ruleValue": "fertilizer"
        }
      ],
      "redeemedCount": 0,
      "redeemedBy": [
        "10936b32-1c60-11eb-adc1-0242ac120002",
        "22229dd2-1c60-11eb-adc1-0242ac120002"
      ],
      "couponExpiry": "2017-07-21T17:32:28Z",
      "createdOn": "2017-07-21T17:32:28Z",
      "updatedOn": "2020-07-21T17:32:28Z"
    }
  ]
});

mock.onGet("/api/coupon/1").reply(200, {
  order: {
    id: uuid(),
    ref: "FAD107",
    promoCode: null,
    value: "55.25",
    currency: "$",
    status: "canceled",
    customer: {
      name: "Ekaterina Tankova",
      address: "Street King William, 42456",
      city: "Montgomery",
      country: "United States",
    },
    items: [
      {
        id: uuid(),
        name: "Project Points",
        cuantity: 25,
        billing: "monthly",
        status: "paid",
        value: "50.25",
        currency: "$",
      },
      {
        id: uuid(),
        name: "Freelancer Subscription",
        cuantity: 1,
        billing: "monthly",
        status: "paid",
        value: "5.00",
        currency: "$",
      },
    ],
    created_at: moment(),
  },
});
