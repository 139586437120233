import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PetsIcon from '@material-ui/icons/Pets';
import BuildIcon from '@material-ui/icons/Build';
import NatureIcon from '@material-ui/icons/Nature';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import { Dashboard } from '@material-ui/icons';

export default [
  {
    subheader: "Service Admin",
    items: [
      {
        icon: Dashboard,
        title: "Dashboard",
        href: "/serviceAdmin/dashboard"
      },
      {
        icon: QuestionAnswerIcon,
        title: "Queries",
        href: "/serviceAdmin/queries",
      },
      {
        icon: TuneIcon,
        title: "Variables",
        items: [
          {
            // icon: BuildIcon,
            href: "/serviceAdmin/variables",
            title: 'Equipments and Water'
          },
          {
            // icon: PetsIcon,
            title: "Animals",
            href: "/serviceAdmin/animals"
          },
          {
            // icon: NatureIcon,
            title: "Crops",
            href: "/serviceAdmin/crops",
          },
        ],

      },
      {
        icon: AssessmentIcon,
        title: "Reports",
        href: "/serviceAdmin/reports",
      },
      {
        icon: SettingsIcon,
        title: "Settings",
        href: "/serviceAdmin/general",
      },
    ]
  }
]
