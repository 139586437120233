import uuid from "uuid/v1";
import moment from "moment";
import mock from "src/utils/mock";

mock.onGet("/api/farmers").reply(200, {
    "totalCount": 3,
    "pageNumber": 0,
    "pageItemsCount": 0,
    "nextPage": 0,
    "previousPage": 0,
    "data": [
        {
            firstName: "Lorem",
            lastName: "Ipsum",
            contactNumber: "7038803365",
            age: 48
        },
        {
            firstName: "Citizen",
            lastName: "Kane",
            contactNumber: "7035803365",
            age: 35
        },
        {
            firstName: "Random",
            lastName: "Farmer",
            contactNumber: "9688033655",
            age: 20
        }

    ]
});
