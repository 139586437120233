/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import LocalGroceryStoreOutlinedIcon from "@material-ui/icons/LocalGroceryStoreOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import TuneIcon from "@material-ui/icons/Tune";
import { Photo } from "@material-ui/icons";
import { Pages } from "@material-ui/icons";

export default [
  {
    subheader: "Super Admin",
    items: [
      {
        icon: DashboardIcon,
        title: "Dashboard",
        href: "/superAdmin/dashboard",
      },
      {
        icon: PersonIcon,
        title: "Users",
        href: "/superAdmin/users",
      },

      {
        icon: StoreMallDirectoryOutlinedIcon,
        title: "Stores",
        href: "/superAdmin/stores",
      },
      {
        icon: StoreMallDirectoryOutlinedIcon,
        title: "Self Pick Up",
        href: "/superAdmin/selfpickup",
      },
      {
        icon: LocalGroceryStoreOutlinedIcon,
        title: "Inventory",
        href: "/superAdmin/inventory",
      },
      {
        icon: LocalGroceryStoreOutlinedIcon,
        title: "Cart Tracking",
        href: "/superAdmin/cart-tracking",
      },
      {
        icon: Photo,
        title: "Social Media",
        href: "/superAdmin/social_media",
      },
      {
        icon: LocalOfferOutlinedIcon,
        title: "Discounts",
        href: "/superAdmin/offers",
      },
      {
        icon: Pages,
        title: "Blogs",
        href: "/superAdmin/blogs",
      },
      // {
      //   title: "Referral Settings",
      //   href: "/superAdmin/referrals",
      // },
      {
        icon: NotificationsActiveIcon,
        title: "Notification Pages",
        href: "/superAdmin/notifications",
      },
      {
        icon: AssessmentOutlinedIcon,
        title: "Reports",
        items: [
          {
            title: "Farmers",
            href: "/superAdmin/farmers",
          },
          {
            title: "Redeem",
            href: "/superAdmin/redeem",
          },
          {
            title: "Orders",
            href: "/superAdmin/orders",
          },
          {
            title: "Orders Tracking Report",
            href: "/superAdmin/order-tracking-report",
          },
          {
            title: "Orders Line Report",
            href: "/superAdmin/order-line-report",
          },
          {
            title: "Visit Reports",
            href: "/superAdmin/visit_reports",
          },
        ],
      },
      {
        icon: TuneIcon,
        title: "Manage",
        // href: "/superAdmin/reports",

        items: [
          {
            title: "Banner",
            href: "/superAdmin/banner",
          },
          {
            title: "Categories",
            href: "/superAdmin/categories",
          },
          {
            title: "Sub Categories",
            href: "/superAdmin/subcategories",
          },
          {
            title: "Delivery Zone",
            href: "/superAdmin/deliveryZones",
          },
          {
            title: "Delivery Charges",
            href: "/superAdmin/deliveryMapper",
          },
          {
            title: "Glossary",
            href: "/superAdmin/glossary",
          },
        ],
      },
      {
        title: "Configuration",
        href: "/superAdmin/config",
      },
      {
        icon: SettingsIcon,
        title: "General Settings",
        href: "/superAdmin/general",
      },
    ],
  },
];
