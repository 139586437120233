
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ListIcon from '@material-ui/icons/List';
import { Assessment, Dashboard } from "@material-ui/icons";

export default [
  {
    subheader: "Sugar Factory Admin",
    items: [
      {
        icon: Dashboard,
        title: "Dashboard",
        href: "/sugarAdminL2/dashboard",
      },
      {
        icon: ListIcon,
        title: "Orders",
        href: "/sugarAdminL2/orders",
      },
      {
        icon: AttachMoneyIcon,
        title: "Credit Settlements",
        items: [
          {
            title: 'Store Credits',
            href: "/sugarAdminL2/credits",
          },
          {
            title: 'Farmer Credits',
            href: "/sugarAdminL2/user_credits",
          },
        ]
      },
      {
        icon: PeopleIcon,
        title: "Subscribed Users",
        href: "/sugarAdminL2/users",
      },
      {
        icon: Assessment,
        title: "Report",
        href: "/sugarAdminL2/reports"
      }
    ],
  },

];
