import { Dashboard, List } from "@material-ui/icons";

export default [
  {
    subheader: "Sugar Factory Admin",
    items: [
      {
        icon: Dashboard,
        title: "Dashboard",
        href: "/sugarAdminL1/dashboard",
      },
      {
        icon: List,
        title: "Orders",
        href: "/sugarAdminL1/orders",
      },
    ],
  },

];
