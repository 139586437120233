import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import { theme, themeWithRtl } from "./theme";
import routes from "./routes";
import GoogleAnalytics from "./components/GoogleAnalytics";
import ScrollReset from "./components/ScrollReset";
import StylesProvider from "./components/StylesProvider";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/main.scss";
import { useDispatch } from "react-redux";
import { loadUser } from "./actions/sessionActions";
import MySnackbar from "./components/Snackbar/Snackbar";

const history = createBrowserHistory();

function App() {
  const [direction, setDirection] = useState("ltr");
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(loadUser());
    }
  }, []);

  return (
    <ThemeProvider theme={direction === "rtl" ? themeWithRtl : theme}>
      <StylesProvider direction={direction}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <ScrollReset />
            <GoogleAnalytics />
            {renderRoutes(routes)}
          </Router>
        </MuiPickersUtilsProvider>
      </StylesProvider>
      <MySnackbar />
    </ThemeProvider>
  );
}

export default App;
