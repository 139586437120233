import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
  brand: {
    fontSize: 26,
    fontWeight: 600,
    color: "white",
    // textTransform: "uppercase",
  },
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <img
          src="/images/logos/fs-logo.png"
          alt="logo"
          style={{ height: "40px", margin: "0 15px" }}
        />
        <RouterLink to="/">
          <Typography className={classes.brand}>FasalSetu</Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
