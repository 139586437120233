/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import {
  AttachMoney,
  List,
  Settings,
  ShoppingCartOutlined,
  Assignment,
  Dashboard,
  Assessment,
} from "@material-ui/icons";

export default [
  {
    subheader: "Store Admin",
    items: [
      {
        icon: Dashboard,
        title: "Dashboard",
        href: "/storeAdmin/dashboard",
      },
      {
        icon: ShoppingCartOutlined,
        title: "Inventory",
        href: "/storeAdmin/inventory",
      },
      {
        icon: List,
        title: "Orders",
        href: "/storeAdmin/orders",
      },
      {
        icon: Assignment,
        title: "Offline Orders",
        href: "/storeAdmin/offline_orders",
      },
      {
        icon: Assignment,
        title: "Services Visits",
        href: "/storeAdmin/services-visits",
      },
      {
        icon: AttachMoney,
        title: "Credit Settlements",
        href: "/storeAdmin/credits",
      },
      {
        icon: Assessment,
        title: "Reports",
        items: [
          {
            title: "Order Report",
            href: "/storeAdmin/report",
          },
          {
            title: "Credit Report",
            href: "/storeAdmin/creditReport",
          },
          {
            title: "Order Tracking Report",
            href: "/storeAdmin/order-tracking-report",
          },
        ],
      },
      {
        icon: Settings,
        title: "Settings",
        href: "/storeAdmin/general",
      },
    ],
  },
];
