/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import DashboardAnalyticsView from "./views/DashboardAnalytics";
import DashboardDefaultView from "./views/DashboardDefault";
import OverviewView from "./views/Overview";
import PresentationView from "./views/Presentation";

export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("src/views/Login")),
      },
      {
        path: "/auth/register",
        exact: true,
        component: lazy(() => import("src/views/Register")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("src/views/Error401")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("src/views/Error404")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("src/views/Error500")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      {
        path: "/calendar",
        exact: true,
        component: lazy(() => import("src/views/Calendar")),
      },
      {
        path: "/changelog",
        exact: true,
        component: lazy(() => import("src/views/Changelog")),
      },
      {
        path: "/chat",
        exact: true,
        component: lazy(() => import("src/views/Chat")),
      },
      {
        path: "/chat/:id",
        exact: true,
        component: lazy(() => import("src/views/Chat")),
      },
      {
        path: "/components/buttons",
        exact: true,
        component: lazy(() => import("src/views/Buttons")),
      },
      {
        path: "/components/cards",
        exact: true,
        component: lazy(() => import("src/views/Cards")),
      },
      {
        path: "/components/chips",
        exact: true,
        component: lazy(() => import("src/views/Chips")),
      },
      {
        path: "/components/forms",
        exact: true,
        component: lazy(() => import("src/views/Forms")),
      },
      {
        path: "/components/lists",
        exact: true,
        component: lazy(() => import("src/views/Lists")),
      },
      {
        path: "/components/modals",
        exact: true,
        component: lazy(() => import("src/views/Modals")),
      },
      {
        path: "/components/typography",
        exact: true,
        component: lazy(() => import("src/views/Typography")),
      },
      {
        path: "/dashboards/analytics",
        exact: true,
        component: DashboardAnalyticsView,
      },
      {
        path: "/dashboards/default",
        exact: true,
        component: DashboardDefaultView,
      },
      {
        path: "/invoices/:id",
        exact: true,
        component: lazy(() => import("src/views/InvoiceDetails")),
      },
      {
        path: "/kanban-board",
        exact: true,
        component: lazy(() => import("src/views/KanbanBoard")),
      },
      {
        path: "/mail",
        exact: true,
        component: lazy(() => import("src/views/Mail")),
      },
      {
        path: "/management/customers",
        exact: true,
        component: lazy(() => import("src/views/CustomerManagementList")),
      },
      {
        path: "/management/customers/:id",
        exact: true,
        component: lazy(() => import("src/views/CustomerManagementDetails")),
      },
      {
        path: "/management/customers/:id/:tab",
        exact: true,
        component: lazy(() => import("src/views/CustomerManagementDetails")),
      },
      {
        path: "/management/projects",
        exact: true,
        component: lazy(() => import("src/views/ProjectManagementList")),
      },
      {
        path: "/management/orders",
        exact: true,
        component: lazy(() => import("src/views/OrderManagementList")),
      },
      {
        path: "/management/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/OrderManagementDetails")),
      },
      {
        path: "/overview",
        exact: true,
        component: OverviewView,
      },
      {
        path: "/presentation",
        exact: true,
        component: PresentationView,
      },
      {
        path: "/profile/:id",
        exact: true,
        component: lazy(() => import("src/views/Profile")),
      },
      {
        path: "/profile/:id/:tab",
        exact: true,
        component: lazy(() => import("src/views/Profile")),
      },
      {
        path: "/projects/create",
        exact: true,
        component: lazy(() => import("src/views/ProjectCreate")),
      },
      {
        path: "/projects/:id",
        exact: true,
        component: lazy(() => import("src/views/ProjectDetails")),
      },
      {
        path: "/projects/:id/:tab",
        exact: true,
        component: lazy(() => import("src/views/ProjectDetails")),
      },
      {
        path: "/projects",
        exact: true,
        component: lazy(() => import("src/views/ProjectList")),
      },
      {
        path: "/settings",
        exact: true,
        component: lazy(() => import("src/views/Settings")),
      },
      {
        path: "/settings/:tab",
        exact: true,
        component: lazy(() => import("src/views/Settings")),
      },
      {
        path: "/social-feed",
        exact: true,
        component: lazy(() => import("src/views/SocialFeed")),
      },
      {
        path: "/getting-started",
        exact: true,
        component: lazy(() => import("src/views/GettingStarted")),
      },
      {
        path: "/superAdmin",
        exact: true,
        component: () => <Redirect to="/superAdmin/dashboard" />,
      },
      {
        path: "/superAdmin/dashboard",
        exact: true,
        component: lazy(() => import("src/views/SuperDashboard")),
      },
      {
        path: "/superAdmin/offers",
        exact: true,
        component: lazy(() => import("src/views/SuperCoupons")),
      },
      {
        path: "/superAdmin/social_media",
        exact: true,
        component: lazy(() => import("src/views/SocialMedia")),
      },
      {
        path: "/superAdmin/deliveryMapper",
        exact: true,
        component: lazy(() => import("src/views/DeliveryMapper/Mapper")),
      },
      {
        path: "/superAdmin/glossary",
        exact: true,
        component: lazy(() => import("src/views/Glossary/Glossary")),
      },
      {
        path: "/superAdmin/offers/addupdate",
        exact: true,
        component: lazy(() => import("src/views/SuperCoupons/AddUpdate")),
      },
      {
        path: "/superAdmin/offers/addupdate/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperCoupons/AddUpdate")),
      },
      {
        path: "/superAdmin/offers/apply/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperCoupons/ApplyCoupon")),
      },
      {
        path: "/superAdmin/users",
        exact: true,
        component: lazy(() => import("src/views/SuperUsers")),
      },
      {
        path: "/superAdmin/visit_reports",
        exact: true,
        component: lazy(() => import("src/views/ServiceManReports")),
      },
      {
        path: "/superAdmin/users/adduser",
        exact: true,
        component: lazy(() => import("src/views/SuperUsers/AddUsers")),
      },
      {
        path: "/superAdmin/users/viewuser/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperUsers/ViewUser")),
      },
      {
        path: "/superAdmin/users/updateUser/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperUsers/UpdateUser")),
      },
      {
        path: "/superAdmin/banner",
        exact: true,
        component: lazy(() => import("src/views/SuperBanner")),
      },
      {
        path: "/superAdmin/categories",
        exact: true,
        component: lazy(() => import("src/views/SuperCategory")),
      },
      {
        path: "/superAdmin/subcategories",
        exact: true,
        component: lazy(() => import("src/views/SuperSubCategory")),
      },
      {
        path: "/superAdmin/deliveryZones",
        exact: true,
        component: lazy(() => import("src/views/SuperWebSettings")),
      },
      {
        path: "/superAdmin/stores",
        exact: true,
        component: lazy(() => import("src/views/SuperStores")),
      },
      {
        path: "/superAdmin/stores/addstore",
        exact: true,
        component: lazy(() => import("src/views/SuperStores/AddStore")),
      },
      {
        path: "/superAdmin/stores/updateStore/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperStores/UpdateStore")),
      },
      {
        path: "/superAdmin/selfpickup",
        exact: true,
        component: lazy(() => import("src/views/SuperSelfPickUp")),
      },
      {
        path: "/superAdmin/selfpickup/addstores",
        exact: true,
        component: lazy(() => import("src/views/SuperSelfPickUp/AddStore")),
      },
      {
        path: "/superAdmin/selfpickup/updateStore/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperSelfPickUp/UpdateStore")),
      },
      {
        path: "/superAdmin/inventory",
        exact: true,
        component: lazy(() => import("src/views/SuperInventory")),
      },
      {
        path: "/superAdmin/cart-tracking",
        exact: true,
        component: lazy(() => import("src/views/SuperCartTracking")),
      },
      {
        path: "/superAdmin/orders",
        exact: true,
        component: lazy(() => import("src/views/SuperOrders/Orders")),
      },
      {
        path: "/superAdmin/blogs",
        exact: true,
        component: lazy(() => import("src/views/SuperBlogs/index")),
      },
      {
        path: "/superAdmin/blog-editor",
        exact: true,
        component: lazy(() => import("src/views/SuperBlogEditor/index")),
      },
      // {
      //   path: "/superAdmin/blog/:id",
      //   exact: true,
      //   component: lazy(() => import("src/views/SuperBlogs/Blog")),
      // },
      {
        path: "/superAdmin/order-tracking-report",
        exact: true,
        component: lazy(() =>
          import("src/views/SuperReports/OrderTracking/OrderReport")
        ),
      },
      {
        path: "/superAdmin/order-line-report",
        exact: true,
        component: lazy(() => import("src/views/SuperReports/OrderLineReport")),
      },
      {
        path: "/superAdmin/farmers",
        exact: true,
        component: lazy(() => import("src/views/SuperReports/Farmer")),
      },
      {
        path: "/superAdmin/redeem",
        exact: true,
        component: lazy(() => import("src/views/SuperReports/Redeem")),
      },
      {
        path: "/superAdmin/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperReports/OrderDetails")),
      },
      {
        path: "/superAdmin/field_visits",
        exact: true,
        component: lazy(() => import("src/views/ServiceManReports")),
      },
      {
        path: "/superAdmin/reports/farmer/:id",
        exact: true,
        component: lazy(() => import("src/views/SuperReports/FarmerView")),
      },
      {
        path: "/superAdmin/config",
        exact: true,
        component: lazy(() => import("src/views/SuperInfoSettings")),
      },
      {
        path: "/superAdmin/notifications",
        exact: true,
        component: lazy(() => import("src/views/SuperNotificationPages")),
      },
      {
        path: "/superAdmin/products/:id/:tab",
        exact: true,
        component: lazy(() => import("src/views/SuperProducts")),
      },

      {
        path: "/superAdmin/:tab",
        exact: true,
        component: lazy(() => import("src/views/ServiceManSettings")),
      },

      {
        path: "/storeAdmin",
        exact: true,
        component: () => <Redirect to="/storeAdmin/dashboard" />,
      },
      {
        path: "/storeAdmin/dashboard",
        exact: true,
        component: lazy(() => import("src/views/StoreDashboard")),
      },
      {
        path: "/storeAdmin/credits",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Credits/Orders")),
      },
      {
        path: "/storeAdmin/credits/:id",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Credits/OrderDetails")),
      },
      {
        path: "/storeAdmin/inventory",
        exact: true,
        component: lazy(() => import("src/views/StoreInventory")),
      },
      {
        path: "/storeAdmin/services-visits",
        exact: true,
        component: lazy(() => import("src/views/StoreServices")),
      },
      {
        path: "/storeAdmin/report",
        exact: true,
        component: lazy(() => import("src/views/StoreReport/Orders")),
      },

      {
        path: "/storeAdmin/order-tracking-report",
        exact: true,
        component: lazy(() =>
          import("src/views/SuperReports/OrderTracking/OrderReport")
        ),
      },
      {
        path: "/storeAdmin/creditReport",
        exact: true,
        component: lazy(() => import("src/views/StoreCreditReport/Orders")),
      },
      {
        path: "/storeAdmin/products/:id/:tab",
        exact: true,
        component: lazy(() => import("src/views/StoreProducts")),
      },

      {
        path: "/storeAdmin/orders",
        exact: true,
        component: lazy(() => import("src/views/StoreOrders/Orders")),
      },
      {
        path: "/storeAdmin/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/StoreOrders/OrderDetails")),
      },
      {
        path: "/storeAdmin/report/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/StoreOrders/OrderDetails")),
      },
      {
        path: "/superAdmin/report/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/StoreOrders/OrderDetails")),
      },
      {
        path: "/storeAdmin/services-visits/addReport",
        exact: true,
        component: lazy(() => import("src/views/StoreServices/AddReport")),
      },
      {
        path: "/storeAdmin/services-visits/addCrop",
        exact: true,
        component: lazy(() => import("src/views/StoreServices/AddCrop")),
      },
      {
        path: "/storeAdmin/services-visits/addFarm",
        exact: true,
        component: lazy(() => import("src/views/StoreServices/AddFarm")),
      },
      {
        path: "/storeAdmin/services-visits/:id",
        exact: true,
        component: lazy(() => import("src/views/StoreServices/VisitsInfo")),
      },
      {
        path: "/storeAdmin/offline_orders",
        exact: true,
        component: lazy(() => import("src/views/StoreOfflineOrders")),
      },
      {
        path: "/storeAdmin/:tab",
        exact: true,
        component: lazy(() => import("src/views/StoreSetting")),
      },

      {
        path: "/sugarAdminL1",
        exact: true,
        component: () => <Redirect to="/sugarAdminL1/dashboard" />,
      },
      {
        path: "/serviceAdmin",
        exact: true,
        component: () => <Redirect to="/serviceAdmin/dashboard" />,
      },
      {
        path: "/sugarAdminL1/orders",
        exact: true,
        component: lazy(() => import("src/views/SugarOrders/Orders")),
      },
      {
        path: "/serviceAdmin/queries",
        exact: true,
        component: lazy(() => import("src/views/ServiceManQueries")),
      },
      {
        path: "/serviceAdmin/crops",
        exact: true,
        component: lazy(() => import("src/views/ServiceManCrops")),
      },
      {
        path: "/serviceAdmin/variables",
        exact: true,
        component: lazy(() => import("src/views/ServiceManVariables")),
      },
      {
        path: "/serviceAdmin/animals",
        exact: true,
        component: lazy(() => import("src/views/ServiceManAnimals")),
      },
      {
        path: "/serviceAdmin/reports",
        exact: true,
        component: lazy(() => import("src/views/ServiceManReports")),
      },
      {
        path: "/serviceAdmin/dashboard",
        exact: true,
        component: lazy(() => import("src/views/ServiceDashboard")),
      },
      {
        path: "/serviceAdmin/:tab",
        exact: true,
        component: lazy(() => import("src/views/ServiceManSettings")),
      },
      {
        path: "/sugarAdminL1",
        exact: true,
        component: () => <Redirect to="/sugarAdminL1/dashboard" />,
      },
      {
        path: "/sugarAdminL1/dashboard",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Dashboard")),
      },
      {
        path: "/sugarAdminL1/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/SugarOrders/OrderDetails")),
      },
      {
        path: "/sugarAdminL2",
        exact: true,
        component: () => <Redirect to="/sugarAdminL2/dashboard" />,
      },
      {
        path: "/sugarAdminL2/dashboard",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Dashboard")),
      },
      {
        path: "/sugarAdminL2/orders",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Orders/Orders")),
      },
      {
        path: "/sugarAdminL2/reports",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Report/Orders")),
      },
      {
        path: "/sugarAdminL2/orders/:id",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Orders/OrderDetails")),
      },
      {
        path: "/sugarAdminL2/credits",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Credits/Orders")),
      },
      {
        path: "/sugarAdminL2/user_credits",
        exact: true,
        component: lazy(() => import("src/views/Sugar2UserCredits/Orders")),
      },
      {
        path: "/sugarAdminL2/users",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Users/Users")),
      },
      {
        path: "/sugarAdminL2/credits/:id",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Credits/OrderDetails")),
      },
      {
        path: "/sugarAdminL2/user_credits/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/Sugar2UserCredits/OrderDetails")
        ),
      },
      {
        path: "/sugarAdminL2/reports/:id",
        exact: true,
        component: lazy(() => import("src/views/Sugar2Report/OrderDetails")),
      },

      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];
